import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../service/auth.service';
import { GlobalDataService } from '../service/global-data.service';
import { SendMessageComponent } from '../shared/send-message/send-message.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = true;
  messageUnreadCount: string;

  navItems = [
    { header: 'Messages', icon: 'message', route: '/sms/messages', role: 'USER' },
    { header: 'Contacts', icon: 'contacts', route: '/contacts', role: 'USER' },
    { header: 'Groups', icon: 'group', route: '/contact-groups', role: 'USER' },
    // { header: 'Signature & Templates', icon: 'group', route: '/contact-groups', role: 'USER' }
  ];

  bsModalRef: BsModalRef;

  /* navDropdownItems = [
    { header: 'Sign out', icon: 'exit_to_app', route: '/USER/profile', role: 'USER' }    
  ]; */


  constructor(
    private modalService: BsModalService,
    private router: Router,
    private globalDataService: GlobalDataService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.messageUnreadCount = '0';
    this.globalDataService.getMessageUnreadCount().subscribe(
      value => {
        this.messageUnreadCount = value;
      });
  }

  /* onLogout(toggleCollapse: boolean) {
    if (toggleCollapse) {
      this.isCollapsed = !this.isCollapsed;
    }  
    this.authService.logout();
  } */

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn();
    //return this.authService.isUserLoggedIn() && this.authService.isLoggedInUserEnabled();
  }

  onLogout() {       
    this.authService.logout();
  }

  openModalWithComponent() {
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component'
    };
    this.bsModalRef = this.modalService.show(SendMessageComponent, {class: 'modal-dialog-centered', backdrop: 'static', initialState});
  }

  closeSideNavAndNavigateToURL(url: string) {
    this.closeNav();
    this.router.navigateByUrl(url);
  }

  closeSideNavAndOpenModalWithComponent() {
    this.closeNav();
    this.openModalWithComponent();
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "200px";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
