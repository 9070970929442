import { Injectable, Inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
//import { HelperProfileSummary } from '../model/helper-profile-summary.model';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    //private headersForEnglish = new Headers({'Content-Type': 'application/json', 'Accept-Language': 'en-us'} );
    //private headers = new Headers({'Content-Type': 'application/json'});

    constructor() { }

    /**
     * Replace the {id} path param in the given url and return a new url
     */
    public prepareUrl(url: string, id: any): string {
        let searchValue = /{id}/gi;
        let preparedUrl = url.replace(searchValue, id);
        return preparedUrl;
    }

    public prepareUrlWithTwoParam(url: string, id1: any, id2: any): string {
        let searchValue1 = /{id1}/gi;
        let searchValue2 = /{id2}/gi;
        let preparedUrl = url.replace(searchValue1, id1);
        let finalPreparedUrl = preparedUrl.replace(searchValue2, id2);
        return finalPreparedUrl;
    }

    // public getHeaders(): Headers {
    //     return this.headers;
    // }

    public getHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    public getHeadersNoCache() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate'
            })
        };
    }

    // public getHeaders() {
    //     return {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    //             'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    //         } )
    //     };
    // }

    public getJSONHeader() {
        let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return httpHeaders;
            // headers: new HttpHeaders({
            //     'Content-Type': 'application/json'
            // });
        
    }

    /**
   * Create a date object from the date string.
   * The string should be in DD/MM/YYYY format
   * @param dateString 
   */
    public createDateFromString(dateString: string): Date {
        let date: Date = null;
        if (dateString != null) {
            let dateParts = dateString.split('/');
            let day = parseInt(dateParts[0], 10);
            let month = parseInt(dateParts[1], 10);
            let year = parseInt(dateParts[2], 10);
            date = new Date(year, month - 1, day);
        }

        return date;
    }

    /**
   * Create a date object from the date string.
   * The string should be in DD/MM/YYYY format
   * @param dateString 
   */
  public convertToISODateFormat(dateString: string): string {
    //isoDateString = "2011-10-05T14:48:00Z";
    //09/03/2020 10:32
    let isoDateString: string = null;
    if (dateString != null) {
        let dateAndTimePart = dateString.split(' ');
        let dateParts = dateAndTimePart[0].split('/');
        /* let day = parseInt(dateParts[0], 10);
        let month = parseInt(dateParts[1], 10);
        let year = parseInt(dateParts[2], 10);

        isoDateString = year + '-' + month + '-' + day + 'T' + dateAndTimePart[1] + 'Z'; */
        isoDateString = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0] + 'T' + dateAndTimePart[1] + 'Z';
    }

    return isoDateString;
}

    

    /* public filterHelperProfilesByName(name: string, helperProfileSummaries: HelperProfileSummary[]): HelperProfileSummary[] {
        console.log('Filter helpers by name : ' + name);
        return helperProfileSummaries.filter(
            helperProfileSummary => helperProfileSummary.name.toUpperCase() == name.toUpperCase()
        );
    } */

    /**
     * Search a array of object to find out the matching objects based on a property value.
     * The search uses a partial search on value
     * @param arrayValues List of objects
     * @param propertyName Property name of the object on which serach need to be performed
     * @param searchString search string value
     * 
     * @returns array of filterd objects with matching criteria
     */
    public filterValuesByProperty(arrayValues: any[], propertyName: string, searchString: string): any[] {
        console.log('Filter array by property : ' + propertyName);        
        /* return arrayValues.filter(
            value => value[propertyName].toUpperCase() == searchString.toUpperCase()
        ); */
        return arrayValues.filter(
            value => value[propertyName].toUpperCase().includes(searchString.toUpperCase())
        );
    }

    /**
     * Store the data in session store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setSessionStorageData(key: string, value: any) {
        sessionStorage.setItem(key, value)
    }

    /**
     * Get the data from the session store
     * @param key 
     */
    public getSessionStorageData(key: string): any {
        return sessionStorage.getItem(key);
    }

    /**
     * Store the object data in session store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setSessionStorageObjectData(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value))
    }

    /**
     * Get the object data from the session store
     * @param key 
     */
    public getSessionStorageObjectData(key: string): any {
        return JSON.parse(sessionStorage.getItem(key));
    }

    public removeSessionStorageData(key: string): any {
        sessionStorage.removeItem(key);
    }

    /**
     * Store the object data in local store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setLocalStorageObjectData(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    /**
     * Get the object data from the local store
     * @param key 
     */
    public getLocalStorageObjectData(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    /**
     * Store the object data in local store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setLocalStorageMapData(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(Array.from(value)));
    }

    /**
     * Get the object data from the local store
     * @param key 
     */
    public getLocalStorageMapData(key: string): any {
        let value = localStorage.getItem(key);
        if (value != null) {
            return new Map(JSON.parse(value));
        } else {
            return null;
        }        
    }

    public removeLocalStorageData(key: string): any {
        localStorage.removeItem(key);
    }

    public scrollToTop() {
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }

}