import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams
  } from '@angular/common/http';
  import { Observable } from 'rxjs';

  import { AppConstants } from '../app.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        // Get the user auth token
        const idToken = localStorage.getItem(AppConstants.authTokenKey);
        //console.log('In auth interceptor : token :: ' + idToken);

        if (idToken) {
            /* const cloned = req.clone({
                headers: req.headers.append("Authorization",
                    "Bearer " + idToken)
            }); */
            let clonedReq = req.clone({
                headers: req.headers.append("X-Auth-Token", idToken)
            });

            const dateInMillis = Date.now();
            const reqWithTimestamp = clonedReq.clone({
                params: (clonedReq.params ? clonedReq.params : new HttpParams())
                           .append('_ts', dateInMillis.toString()) /*.... add new params here .....*/ 
              });

            //console.log('In auth interceptor : modified request :: ' + JSON.stringify(cloned));

            return next.handle(reqWithTimestamp);
        }
        else {
            return next.handle(req);
        }
    }
}