import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { SystemConfig } from '../model/system-config.model';
import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor(
    private http: HttpClient,    
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  public getSystemConfig(): Observable<SystemConfig> {
    return this.http.get<SystemConfig>(AppConstants.endpoint.static_data.system_config)
      .pipe(catchError(this.exceptionService.handleError));
  }
}
