import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

import { AppConstants } from '../../app.constants';
import { SmsService } from '../../service/sms.service';
import { StaticDataService } from '../../service/static-data.service';
import { ContactService } from '../../service/contact.service';
import { GlobalDataService } from '../../service/global-data.service';
import { ContactGroupService } from '../../service/contact-group.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { LoggerService } from '../../service/logger.service';
import { ApiError } from '../../model/api-error';
import { Contact } from '../../model/contact.model';
import { ContactGroup } from '../../model/contact-group.model';
import { SystemConfig } from '../../model/system-config.model';
import { OutgoingSms } from '../../model/outgoing-sms.model';

import { PhoneNumberValidator } from '../../validator/phone-number.validator';


@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit, AfterViewInit {

  @ViewChild("inputTo", {static: false})  inputTo: ElementRef;
  @ViewChild("inputContactGroup", {static: false})  inputContactGroup: ElementRef;

  title: string;
  closeBtnName: string;
  list: any[] = [];

  maxlengthMessage = 0;
  apiError: ApiError;
  duplicateContactError: ApiError;
  smsMsgForm: FormGroup;
  isFormSubmit = false;
  isFormSubmitSuccess = false;

  contact: Contact;
  contacts: Contact[];
  contactGroups: ContactGroup[];

  // selectedOption: any;
  selectedContacts: Contact[] = [];
  selectedContactGroups: ContactGroup[] = [];
  noResult = false; // become true if no contact result found in the typeahead control
 
  constructor(
    private fb: FormBuilder,
    private smsService: SmsService,
    private staticDataService: StaticDataService,
    private globalDataService: GlobalDataService,
    private logger: LoggerService,
    private contactService: ContactService,
    private contactGroupService: ContactGroupService,
    public formUtils: FormUtilsService,
    public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
    //this.list.push('PROFIT!!!');
    this.createForm(); 
    this.getSystemConfig();
    this.getContacts();  
    this.getContactGroups(); 
  }

  /**
   * If a contact is passed from a screen ( ex. contactList) , put it in already selected contact
   */
  ngAfterViewInit(): void {
    if (this.contact) {
      this.selectedContacts.push(this.contact);
    }
  }

  createForm() {   
    this.smsMsgForm = this.fb.group({
      //receiverNumber: [null, Validators.pattern(AppConstants.pattern.phone)],
      receiverNumber: [null, PhoneNumberValidator.validatePhoneNumber],
      groupName: [null],
      //smsText: [null, [Validators.required, Validators.maxLength(this.maxlengthMessage)]]      
      smsText: [null]      
    });
  }

  getContacts() {
    this.apiError = null;
    this.contactService.getContacts()
      .subscribe(
        data => {          
          this.contacts = data;
          this.logger.debug('Got the user contacts data : ' + JSON.stringify(this.contacts));
        },
        error => {
          this.logger.debug('Error while user contacts data : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  getSystemConfig() {
    this.apiError = null;
    this.staticDataService.getSystemConfig()
      .subscribe(
        data => {          
          //this.contacts = data;
          this.logger.debug('Got the system config data : ' + JSON.stringify(data));
          this.maxlengthMessage = Number(data.maxMessageLength);
          this.smsMsgForm.get('smsText').setValidators([Validators.required, Validators.maxLength(this.maxlengthMessage)]);
          this.smsMsgForm.get('smsText').updateValueAndValidity();
        },
        error => {
          this.logger.debug('Error while getting system config data : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  getContactGroups() {
    this.apiError = null;
    this.contactGroupService.getContactGroups()
      .subscribe(
        data => {          
          this.contactGroups = data;
          this.logger.debug('Got the user contact groups data - size: ' + this.contactGroups.length);
        },
        error => {
          this.logger.debug('Error while user contact groups data : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  /**
   * Send the message to the selected receipients
   */
  onSubmit() {
    this.apiError = null;
    // select the selected sms summary phone number as receiver number as we want to send him a sms
    this.logger.debug('Submitted form value : ' + JSON.stringify(this.smsMsgForm.value));
    
    if (this.smsMsgForm.valid) {
      let outgoingSms = new OutgoingSms();
      outgoingSms.receiverNumbers = [];
      // add all the selected contacts to receiver numbers
      for (let contact of this.selectedContacts) {
        outgoingSms.receiverNumbers.push(contact.number);
      }   
      outgoingSms.groupNames = [];
      // add all the selected contact groups
      for (let contactGroup of this.selectedContactGroups) {
        outgoingSms.groupNames.push(contactGroup.groupName);
      }    
      outgoingSms.smsText = this.smsMsgForm.value.smsText;
      this.logger.debug('The outgoing sms value : ' + JSON.stringify(outgoingSms));

      this.isFormSubmit = true;      
      this.smsService.sendSms(outgoingSms).subscribe(
        data => {
          this.logger.debug('Successfully posted user sms : ' + JSON.stringify(data));
          this.apiError = null;
          this.isFormSubmit = false; 

          // now send the notification to reload the sms summary screen
          this.globalDataService.setSendMessageNotification();
          
          this.smsMsgForm.reset();
          this.bsModalRef.hide();
        },
        error => {
          this.logger.error('Error while posting user sms : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }      
      );
    } else {
      // show validation error
      this.formUtils.markFormGroupTouched(this.smsMsgForm); 
    } 
  }

  /**
   * When a user select a contact in typeahead, add it to selected list
   * @param event 
   */
  onSelect(event: TypeaheadMatch): void {
    /* this.selectedOption = event.item;
    this.logger.debug('Selected contact : ' + JSON.stringify(this.selectedOption)); */
    this.duplicateContactError = null;
    if (this.isContactAlreadyAdded(event.item)) {
      this.duplicateContactError = new ApiError();
      this.duplicateContactError.message = 'The selected contact is alread added : ' + event.item.fullName;
    } else {
      this.selectedContacts.push(event.item);
    } 
    this.smsMsgForm.get('receiverNumber').reset();
    this.inputTo.nativeElement.focus();
  }

  isContactAlreadyAdded(contact: Contact): boolean {
    let result = false;
    for (let c of this.selectedContacts) {
      if (c.number == contact.number) {
        result = true;
        break;
      }
    }

    return result;
  }

  /**
   * When a user select a contact group in typeahead, add it to selected list
   * @param event 
   */
  onSelectContactGroup(event: TypeaheadMatch): void { 
    this.duplicateContactError = null;   
    if (this.isContactGroupAlreadyAdded(event.item)) {
      this.duplicateContactError = new ApiError();
      this.duplicateContactError.message = 'The selected contact group is alread added : ' + event.item.groupName;
    } else {
      this.selectedContactGroups.push(event.item);
    }    
    this.smsMsgForm.get('groupName').reset();
    this.inputContactGroup.nativeElement.focus();
  }

  isContactGroupAlreadyAdded(contactGroup: ContactGroup): boolean {
    let result = false;
    for (let c of this.selectedContactGroups) {
      if (c.groupName == contactGroup.groupName) {
        result = true;
        break;
      }
    }
    return result;
  }

  /**
   * If no result is found in typeahead, mark the indicator
   * @param event 
   */
  typeaheadNoResults(event: boolean): void {
    this.logger.debug('Got no result');
    this.noResult = event;
  }

  /**
   * Add the contact which is not in the user contact list and focus back to input field
   */
  onFocusOutAddManualContact() {
    if (this.noResult) {
      this.logger.debug('Adding manual contact from formcontrol : ' + JSON.stringify(this.smsMsgForm.value.receiverNumber));
      if (this.smsMsgForm.get('receiverNumber').valid) {
        this.duplicateContactError = null;
        let manualContact = new Contact();
        manualContact.firstName = this.smsMsgForm.value.receiverNumber;
        manualContact.number = this.smsMsgForm.value.receiverNumber;
        if (this.isContactAlreadyAdded(manualContact)) {
          this.duplicateContactError = new ApiError();
          this.duplicateContactError.message = 'The phone number is alread added : ' + manualContact.number;
        } else {
          this.selectedContacts.push(manualContact);          
        }
        this.smsMsgForm.get('receiverNumber').reset();
          this.inputTo.nativeElement.focus();
          this.noResult = false; // need to make it false so it accidently don't add any contact
          this.smsMsgForm.get('receiverNumber').markAsUntouched;
      } else {
        this.smsMsgForm.get('receiverNumber').markAsTouched;
      }
    }
  }

  /**
   * Remove the contact from the to field and focus on the input field
   * @param number contact number to be removed
   */
  onRemoveContactFromToField(number: string) {
    this.logger.debug('Contact to be removed from to field : ' + number);

    for (let index in this.selectedContacts) {
      if (this.selectedContacts[index].number == number) {
        this.logger.debug('Removing contact from to field at index : ' + index);
        this.selectedContacts.splice(Number(index), 1);    
        this.inputTo.nativeElement.focus(); // focus on the to field    
      }
    }
  }

  onRemoveContactGroupFromToField(groupName: string) {
    this.logger.debug('ContactGroup to be removed from to field : ' + groupName);

    for (let index in this.selectedContactGroups) {
      if (this.selectedContactGroups[index].groupName == groupName) {
        this.logger.debug('Removing contact group from to field at index : ' + index);
        this.selectedContactGroups.splice(Number(index), 1);    
        this.inputContactGroup.nativeElement.focus(); // focus on the contact group field    
      }
    }
  }

  

}
