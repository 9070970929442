import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

// import { StaticData } from '../model/static-data.model';
// import { Country } from '../model/country.model';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  constructor() { }

  /**
   * check if the given field has any validation error
   * @param formGroup 
   * @param fieldName 
   */
  public hasValidationError(formGroup: FormGroup, fieldName: string): boolean {
    return !this.isDisabled(formGroup, fieldName) && !this.isValid(formGroup, fieldName) && this.isTouched(formGroup, fieldName);
  }

  /**
   * Check if the given field has the a specific validation error
   * @param formGroup 
   * @param fieldName 
   * @param validation Name of the validation
   */
  public showValidationHint(formGroup: FormGroup, fieldName: string, validation: string): boolean {
    return this.hasError(formGroup, fieldName, validation) && this.isTouched(formGroup, fieldName);
  }

  public isValid(formGroup: FormGroup, fieldName: string): boolean {
    /* let result = formGroup.get(fieldName).valid;
    console.log('Field Name : ' + fieldName + ' :: valid : ' + result);
    return result; */
    return formGroup.get(fieldName).valid;
  }

  public isTouched(formGroup: FormGroup, fieldName: string): boolean {
    return formGroup.get(fieldName).touched;
  }

  public isDisabled(formGroup: FormGroup, fieldName: string): boolean {
    return formGroup.get(fieldName).disabled;
  }

  public hasError(formGroup: FormGroup, fieldName: string, validation: string): boolean {
    return formGroup.get(fieldName).hasError(validation);
  }

  /**
   * Mark all the controls of the form as touched.
   * It will mark all the controls as touched including the nested/child controls.
   * @param formGroup 
   */
  public markFormGroupTouched(formGroup: FormGroup) {
    if (formGroup.controls) {
      const keys = Object.keys(formGroup.controls);
      for (let i = 0; i < keys.length; i++) {
        const control = formGroup.controls[keys[i]];
  
        if (control instanceof FormControl) {
          control.markAsTouched();
        } else if (control instanceof FormGroup) {
          this.markFormGroupTouched(control);
        } 
       /*  else if (control instanceof FormArray) {
          for (let formArrayControl of control) {
            this.markFormGroupTouched(formArrayControl);
          }
          
        } */
      }
    }
  }

  /* public compareStaticData(m1: StaticData, m2: StaticData): boolean {
    let result = m1 && m2 ? m1.id === m2.id : m1 === m2;
    return result;
  }

  public compareCountry(m1: Country, m2: Country): boolean {
    let result = m1 && m2 ? m1.id === m2.id : m1 === m2;
    return result;
  } */

  /**
   * Get the server side field validation error for the given field.
   * @param errorInfo Server side validation error info
   * @param fieldName model field name
   * @returns if found, field error message else null
   */
  /* public showFieldError(errorInfo: ErrorInfo, fieldName: string): string {
    let errorMessage = null;
    if (!isNullOrUndefined(errorInfo) && !isNullOrUndefined(errorInfo.errors)) {
      for (let error of errorInfo.errors) {
        if (fieldName == error.field) {
          errorMessage = error.message;
        }
      }
    }
    
    return errorMessage;
  } */

}
