// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'http://localhost:8081/ombrexplus/api',
  //ombrexApiBaseUrl: 'https://man-api.ombrex.com',
  ombrexApiBaseUrl: 'https://radio-api.ombrex.com',
  // google people api - ombrex_sms
  googleOmbrexApiKey: 'AIzaSyB235pggti_UWETgQlk2TRDnGBUwsq60NI',
  googleOmbrexClientId: '779678901612-7hrbv63hsrg42alep10ls8lqk5bm4ruh.apps.googleusercontent.com',

  // dial out numbers
  playAdsNumber: '102',
  startStreamNumber: '6789'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
