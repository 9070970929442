import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiErrorComponent } from '../shared/api-error/api-error.component';



@NgModule({
  declarations: [ApiErrorComponent],
  imports: [
    CommonModule
  ],
  exports: [ApiErrorComponent]
})
export class ApiErrorModule { }
