import { AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export class PhoneNumberValidator {

    static validatePhoneNumber(control: AbstractControl) {
        let validNumber = true;
        try {
            const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, 'US');
            //validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
            validNumber = phoneNumberUtil.isPossibleNumber(phoneNumber);
            if (!validNumber) {
                console.log('Invalid number : ' + phoneNumberUtil.isPossibleNumberWithReason(phoneNumber))
            }
        } catch (e) { }

        return validNumber ? null : { 'invalidNumber': { value: control.value } };
    }
}