import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  messageUnreadCount = new BehaviorSubject<string>('0');

  // observer to decide when to scroll to bottom after loading the sms conversation
  smsMessageConversationScrollToBottom = new Subject<void>();

  // this notification is generated when a user send a message using the send message popup screen
  sendMessageNotification = new Subject<void>();

  constructor() { }

  setMessageUnreadCount(count: string) {
    this.messageUnreadCount.next(count);
  }

  getMessageUnreadCount(): Observable<any> {
    return this.messageUnreadCount.asObservable();
  }

  setSmsMessageConversationScrollToBottom() {
    this.smsMessageConversationScrollToBottom.next();
  }

  getSmsMessageConversationScrollToBottom() {
    return this.smsMessageConversationScrollToBottom.asObservable();
  }

  setSendMessageNotification() {
    this.sendMessageNotification.next();
  }

  getSendMessageNotification() {
    return this.sendMessageNotification.asObservable();
  }

}
