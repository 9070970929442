import { environment } from '../environments/environment';

export const AppConstants = {
    endpoint: {

        ombrex: {
            auth: {
                login: environment.ombrexApiBaseUrl + '/v2/user_auth'
            },
            conference: {
                base: environment.ombrexApiBaseUrl + '/v2/accounts/{id}/conferences',
                parked_calls: environment.ombrexApiBaseUrl + '/v2/accounts/{id}/parked_calls',
                account: environment.ombrexApiBaseUrl + '/v2/accounts/{id}',
            }
        },
        
        auth: {
            login: environment.apiBaseUrl + '/auth/login'
        },
        contact: {
            base: environment.apiBaseUrl + '/contacts',
            list: environment.apiBaseUrl + '/contacts/list'
        },
        
        contact_group: {
            base: environment.apiBaseUrl + '/contact-groups',
            get: environment.apiBaseUrl + '/contact-groups/{id}',
            contact_number: environment.apiBaseUrl + '/contact-groups/{id}/phone-numbers'
        },
        sms: {
            account: environment.apiBaseUrl + '/sms/account',
            send: environment.apiBaseUrl + '/sms/send',
            receive: environment.apiBaseUrl + '/sms/receive',
            unread_count: environment.apiBaseUrl + '/sms/unread-count',
            view: environment.apiBaseUrl + '/sms/view',
            view_contact: environment.apiBaseUrl + '/sms/view/contact/{id}',
            view_contact_page_count: environment.apiBaseUrl + '/sms/view/contact/{id}/page-count',
            mark_as_read: environment.apiBaseUrl + '/sms/{id}/mark-as-read',
            mark_all_as_read_contact: environment.apiBaseUrl + '/sms/view/contact/{id}/mark-as-read'
        },
        static_data: {
            system_config: environment.apiBaseUrl + '/static-data/system-config'
        }
    },

    pattern: {
        phone: '[0-9]{8,14}',
        digit: /^[0-9]/g
    },
       
    char_more: '...',
    authTokenKey: 'radio_app_id_token', // // use to store the authenticated user token
    http_header_page_count: 'X-Page-Count',
    http_header_total_count: 'X-Total-Count',
    http_header_unread_count: 'X-Unread-Count',
    
    //authUserKey: 'auth_user', // use to store the authenticated user profile
    //appConfig: 'helphunt_app_config'
    storage: {
        local: {
            managed_participants_key: 'radio_app_managed_par_key'
        }
    }

};
