import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { Contact } from '../model/contact.model';
import { ContactNumberHolder } from '../model/contact-number-holder.model';


import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient,    
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  public getContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(AppConstants.endpoint.contact.base)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public saveContactList(contactNumberHolder: ContactNumberHolder): Observable<any> {
    return this.http.post<any>(AppConstants.endpoint.contact.list, contactNumberHolder, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

}
