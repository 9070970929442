// represents the summary of the contact

export class Contact {
    contactId: string;
	phoneNumberId: string;
	firstName: string;
	lastName: string;
	fullName: string;
	number: string;
	numberType: string;
	nameAndNumber: string;
}