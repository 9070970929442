import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { ContactGroup } from '../model/contact-group.model';
import { GroupPhoneNumber } from '../model/group-phone-number.model';

import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService {

  constructor(
    private http: HttpClient,    
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  public createContactGroup(value: any): Observable<ContactGroup> {
    return this.http.post<ContactGroup>(AppConstants.endpoint.contact_group.base, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getContactGroups(): Observable<ContactGroup[]> {      
    return this.http.get<ContactGroup[]>(AppConstants.endpoint.contact_group.base)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateContactGroup(contactGroupId: number, value: any): Observable<ContactGroup> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.contact_group.get, contactGroupId);   
    return this.http.put<ContactGroup>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteContactGroup(contactGroupId: number): Observable<ContactGroup> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.contact_group.get, contactGroupId);   
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public addPhoneNumberToContactGroup(contactGroupId: number, phoneNumberId: string): Observable<GroupPhoneNumber> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.contact_group.contact_number, contactGroupId);

    let params = new HttpParams();    
    params = params.append('phoneNumberId', phoneNumberId);

    return this.http.post<GroupPhoneNumber>(url, null, {headers: this.utilService.getJSONHeader(), params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getPhoneNumberOfContactGroup(contactGroupId: number): Observable<GroupPhoneNumber[]> {   
    let url = this.utilService.prepareUrl(AppConstants.endpoint.contact_group.contact_number, contactGroupId);   
    return this.http.get<GroupPhoneNumber[]>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public removePhoneNumberOfContactGroup(contactGroupId: number, groupPhoneNumberId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.contact_group.contact_number, contactGroupId);
    url = url + '/' + groupPhoneNumberId;    

    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }
}
