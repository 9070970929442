import { Component, Input } from '@angular/core';

import { ApiError } from '../../model/api-error';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss']
})
export class ApiErrorComponent {

  @Input()
  public apiError: ApiError;

  constructor() { }

  onClickClose() {
    this.apiError = null;
  }

}
