import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { SmsSummary } from '../model/sms-summary.model';
import { OutgoingSms } from '../model/outgoing-sms.model';
import { SmsDetails } from '../model/sms-details.model';
import { SmsDetailsPageCount } from '../model/sms-details-page-count.model';

import { RestResponse } from '../model/rest-response.model';

import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private http: HttpClient,    
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  public getSmsUnreadCountForUser(): Observable<HttpResponse<any>> { 
    return this.http.get<HttpResponse<any>>(AppConstants.endpoint.sms.unread_count, {observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }
  
  public viewSmsSummeriesForUser(searchString: string, pageNumber: number): Observable<HttpResponse<SmsSummary[]>> {  
    let params = new HttpParams();
    if (searchString != null) {
      params = params.append('searchString', searchString);
    }
    params = params.append('pageNumber', pageNumber.toString());
    
    return this.http.get<SmsSummary[]>(AppConstants.endpoint.sms.view, {params: params, observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getSmsDetailsForUserContact(contactNumber: string, searchString: string, pageNumber: string): Observable<SmsDetails[]> {  
    let url = this.utilService.prepareUrl(AppConstants.endpoint.sms.view_contact, contactNumber);
    let params = new HttpParams();
    if (searchString != null) {
      params = params.append('searchString', searchString);
    }
    params = params.append('pageNumber', pageNumber);
    
    return this.http.get<SmsDetails[]>(url, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getSmsDetailsPageCountForUserContact(contactNumber: string, searchString: string): Observable<SmsDetailsPageCount> {  
    let url = this.utilService.prepareUrl(AppConstants.endpoint.sms.view_contact_page_count, contactNumber);
    let params = new HttpParams();
    if (searchString != null) {
      params = params.append('searchString', searchString);
    }
    
    return this.http.get<SmsDetailsPageCount>(url, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public markSmsAsRead(smsId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.sms.mark_as_read, smsId);
    return this.http.put<any>(url, null, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public markAllSmsAsReadForContact(contactNumber: string): Observable<RestResponse> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.sms.mark_all_as_read_contact, contactNumber);
    return this.http.put<RestResponse>(url, null, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public sendSms(outgoingSms: OutgoingSms): Observable<SmsDetails[]> {
    return this.http.post<SmsDetails[]>(AppConstants.endpoint.sms.send, outgoingSms, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }
}
