import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  URL_LOGIN = '/auth/login';
  URL_ACCOUNT_DISABLED = '/auth/account-disabled';
  URL_SMS_MESSAGES = '/sms/messages';
  URL_RADIO = '/radio/live-callers';

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: LoggerService,
    private exceptionService: ExceptionService,
    private utilService: UtilService
  ) { }

  public login(value: any): Observable<any> {
    return this.http.post(AppConstants.endpoint.auth.login, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public ombrexLogin(value: any): Observable<any> {
    return this.http.put(AppConstants.endpoint.ombrex.auth.login, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  // logout the user and redirect to login page
  public logout() {
    localStorage.removeItem(AppConstants.authTokenKey);
    //localStorage.removeItem(AppConstants.authUserKey);
    this.router.navigateByUrl(this.URL_LOGIN);
  }

  /** 
   * Check if we have a logged in user
  */
  public isUserLoggedIn(): boolean {
    let isTokenExpired = jwtHelper.isTokenExpired(this.getAuthToken());
    // this.logger.debug('User login status : ' + !isTokenExpired);
    return !isTokenExpired;
    //return false;
  }

  public getLoggedInUser() {
    return jwtHelper.decodeToken(this.getAuthToken());
  }

  /**
   * User radio conference account id
   */
  public getLoggedInUserAccountId() {
    // We are using email as the username
    return this.getLoggedInUser().account_id;
  }

  public getLoggedInUserEmail() {
    // We are using email as the username
    return this.getLoggedInUser().email;
  }

  public isLoggedInUserEnabled(): boolean {
    if (this.getLoggedInUser().enable == 'true')
      return true;
    else
      return false;
  }

  public getLoggedInUserSmsPhoneNumber() {
    return this.getLoggedInUser().smsPhoneNumber;
  }

  /**
   * Get the UTC offset for the user timezone
   */
  public getLoggedInUserTimezoneOffsetUTC() {
    return this.getLoggedInUser().timezoneOffsetUTC;
  }

  public getAuthToken() {
    let token = localStorage.getItem(AppConstants.authTokenKey);
    return token;
  }

  public setAuthToken(token: string) {
    localStorage.setItem(AppConstants.authTokenKey, token);
    this.logger.debug('Setting the auth token :' + token);
  }

  public redirectLoggedInUserByRole() {
    /* this.logger.debug('User account enabled : ' + this.isLoggedInUserEnabled());
    if (this.isLoggedInUserEnabled()) {
      this.logger.debug('User enabled, redirecting to messages');
      this.router.navigateByUrl(this.URL_SMS_MESSAGES);
    } else {
      this.logger.debug('User disabled, redirecting to error page');
      this.router.navigateByUrl(this.URL_ACCOUNT_DISABLED);
    } */
    this.router.navigateByUrl(this.URL_RADIO);

  }


}
